<template>
  <div class="organization_detail">
    <a-spin :spinning="loading">
      <div class="title">组织名录</div>
      <div class="tit">{{ detail.organizationName || "暂无" }}</div>
      <a-row :gutter="50">
        <a-col :span="11">
          <div class="pic">
            <img :src="fileUrl.url!=''?fileUrl.url:fileUrl.defaultUrl" alt="" />
          </div>
          <div class="info">
            <p><span>统一社会信用代码：</span>{{ detail.socialCreditCode || "暂无" }}</p>
            <p><span>所在地址：</span>{{ detail.villageAddress || "暂无" }}</p>
            <p><span>组织名称：</span>{{ detail.organizationName || "暂无" }}</p>
            <p><span>单位/组织联系电话：</span>{{ detail.organizationPhone?detail.organizationPhone.replace(detail.organizationPhone.slice(-8,-4),"*****"):"暂无" }}</p>
            <p><span>村详细地址：</span>{{ detail.villageAddress || "暂无" }}</p>
          </div>
        </a-col>
        <a-col :span="13">
          <div class="info">
            <p><span>法定代表人姓名：</span>{{ detail.legalRepresentativeName || "暂无" }}</p>
            <p><span>法定代表人证件号：</span>{{ detail.legalRepresentativeCard?detail.legalRepresentativeCard.replace(detail.legalRepresentativeCard.slice(-16,-2),"*************"):"暂无" }}</p>
            <p><span>法定代表人手机：</span>{{ detail.legalRepresentativePhone?detail.legalRepresentativePhone.replace(detail.legalRepresentativePhone.slice(-8,-4),"****"):"暂无" }}</p>
            <p><span>成员数：</span>{{ detail.numOfMembers || "暂无" }}</p>
            <p><span>统一经营面积：</span>{{ detail.unifiedManagerArea || "暂无" }}</p>
            <p><span>集体土地面积：</span>{{ detail.collectiveLandArea || "暂无" }}</p>
            <p><span>经度：</span>{{ detail.longitude || "暂无" }}</p>
            <p><span>纬度：</span>{{ detail.latitude || "暂无" }}</p>
            <p><span>成立日期：</span>{{ detail.incorporationDate }}</p>
            <p><span>集体土地总面积：</span>{{ detail.unifiedManagerArea || 0 }} 亩</p>
            <p><span>业务范围：</span>{{ detail.scopeOfBusiness || "暂无" }}</p>
          </div>
        </a-col>
      </a-row>
      <div class="title">详细资料</div>
      <div class="detail">
        {{ detail.villageDetails || "暂无详细资料" }}
      </div>
    </a-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/organization";
export default {
  data() {
    return {
      detail: null,
      fileUrl:{
        url:'',
        defaultUrl:require('@/assets/images/village-default.png'),
      },
      loading: false,
    };
  },
  components: {},
  created() {
    this.getDetail();
    this.getVillageImagesFiles();
  },
  methods: {
    getDetail() {
      this.loading = true;
      Api.villageDetail(this.$route.params.id).then((res) => {
        this.detail = res.result;
        this.loading = false;
        Api.clickVillage(res.result.organizationName);
      });
    },
    getVillageImagesFiles() {
      Api.getVillageImagesFiles({villageId:this.$route.params.id,fileType:2}).then((res) => {
        if(res.result.length>0){
          this.fileUrl.url = res.result[0].url;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.organization_detail {
  .title {
    line-height: 36px;
    background: url("~@/assets/images/lx.png") no-repeat 10px center #f9f9f9;
    font-size: 16px;
    color: #008840;
    text-indent: 40px;
  }
  .pic {
    img {
      width: 100%;
      height: 300px;
    }
  }
  .tit {
      line-height: 2.5em;
      font-size: 24px;
      color: #007929;
    }
  .info {
    p {
      line-height: 2em;
      span {
        color:#007A2D;
      }
    }
  }
  
  .detail {
    line-height: 2em;
    padding: 0 20px;
    margin-bottom: 30px;
  }
}
</style>
